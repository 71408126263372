import { Watermark } from 'antd';
import { useState,useMemo } from 'react';
import ValuesStore from '../../store/values-store';

const Landing = ({ homepage }) => {
    const valuesStore = ValuesStore();
    const[schoolDetails,setSchoolDetails]=useState();
    useMemo(() => {
        
        setTimeout(() => {
            const institution = valuesStore.getArrayObjectsValue('settings', 'prop', 'INSTITUTION_DETAILS')?.value;
            if (institution) {
                setSchoolDetails(JSON.parse(institution));            
            }
        }, 1000);

    }, []);
    return (
        <>
            <Watermark content={[schoolDetails?.alias, 'ERP SYS']}>
                <div                    
                    style={{
                        height: 600,
                    }}
                />
            </Watermark>
        </>

    )
}

export default Landing;

