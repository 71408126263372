import React, { useEffect, useMemo, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './css/mdb.min.css';
import './font/fontawesome/css/all.min.css'
import './css/custom/animations.css';
import './css/custom/tweeks.css';
import './css/bootstrap5.min.css';

import "./css/styles/main.css";
import "./css/dashadmin_style.css";
import "./css/core.css";


import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
// import usePhoneBaseMenu from './js/components/phoneBaseMenu';
import useWindowDimensions from './js/components/screensize';

import SettingsStore from './js/store/settings-store';
import ValuesStore from './js/store/values-store';
import utils from './js/dependencies/custom/react-utilities'

//admin paths
import AdminHome from './js/pages/admin/home';
import AdminHome2 from './js/pages/admin/home_green';
import Jobs from './js/pages/admin/jobs';
import AcadYear from './js/pages/admin/acad_year';
import Transcript from './js/pages/admin/transcript';
import CourseManagement from './js/pages/admin/course_assignment';
import CourseManagementGeneral from './js/pages/admin/course_mgt_general';
import CourseMgtExtra from './js/pages/admin/course_mgt_extra';
import Courses from './js/pages/admin/courses';
import EduLevel from './js/pages/admin/edu_level';
import MountedCourses from './js/pages/admin/mounted_courses';
import OrgType from './js/pages/admin/org_type';
import AdminSetting from './js/pages/admin/settings';
import AdminFiles from './js/pages/admin/admin_files';
import AdminPerms from './js/pages/admin/admin_perms';
import AdminRoles from './js/pages/admin/admin_roles';
import Admin from './js/pages/admin/admin';
import BatchCalendar from './js/pages/admin/batch_calendar';
import Grade from './js/pages/admin/grade';
import Level from './js/pages/admin/level';
import Programs from './js/pages/admin/programs';
import Scheme from './js/pages/admin/scheme';
import ScoreSheetSummary from './js/pages/admin/score_sheet_summary';
import ScoreSheet from './js/pages/admin/score_sheet';
import Semester from './js/pages/admin/semester';
import Sessions from './js/pages/admin/sessions';
import StudentRegistrations from './js/pages/admin/student_registrations';
import StudentType from './js/pages/admin/student_type';
import Student from './js/pages/admin/students';
import AdminRoleFilesLink from './js/pages/admin/admin_role_files_link';
import AdminRolePerm from './js/pages/admin/admin_role_perm';
import AdminRoleLink from './js/pages/admin/admin_role_link';
import MarkEntry from './js/pages/admin/mark_entry';
import MarkEntryGeneral from './js/pages/admin/mark_entry_general';
import GradeChange from './js/pages/admin/grade_change';
import AdminDept from './js/pages/admin/admin_dept';
import Page404 from './js/pages/admin/404';
import Landing from './js/pages/admin/landing';
import MountedCoursesGeneral from './js/pages/admin/mounted_courses_general';

import Login from './js/pages/admin/login';
import Broadsheet from './js/pages/admin/broadsheet';
import RegistrationPeriods from './js/pages/admin/registration_periods';
import ChangePassword from './js/pages/admin/change_password';
import InitRecoverPassword from './js/pages/admin/init_recover_password';
import CompleterecoverPassword from './js/pages/admin/complete_recover_password';
import StudentPasswordRecovery from './js/pages/admin/student_password_recovery';
import CheckIndex from './js/pages/admin/check_index';
import GradeChangeGeneral from './js/pages/admin/grade_change_general';
import ExtraGradesCorrection from './js/pages/admin/extra_grades_correction';
import GradeHx from './js/pages/admin/grade_hx';
import GradeChangeExtra from './js/pages/admin/grade_change_extra';
import Uploads from './js/pages/admin/student_files';
import CourseEvaluation from './js/pages/admin/course_evaluation';
import Evaluation from './js/pages/admin/evaluation';


function App() {
  const settingsStore = SettingsStore();
  const valuesStore = ValuesStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { vpWidth } = useWindowDimensions();
  // const phoneBaseMenu = usePhoneBaseMenu();

  // const [homePageCardSize, setHomePageCardSize] = useState('3');  
  // const [bannerType, setBannerType] = useState('jumbotron');

  // const fetchItems = [
  //   {
  //     method: 'post',
  //     sql: 'SELECT * FROM settings WHERE is_public = 1',
  //     url: `${Settings.backend}/bootstrap_others`,
  //     storeName: 'configSettings',
  //   }
  // ];

  const newLocation = useLocation();
  const urlFileName = utils.getUrlFileName();

  function hasRoute() {
    const timer = setInterval(() => {
      const routesAvailable = valuesStore.getValue('permitted_routes');
      if (routesAvailable.length > 0) {
        clearInterval(timer);
        const routes = valuesStore.getArrayObjectsValue('permitted_routes', 'path', urlFileName);
        if (Object.keys(routes).length <= 0) {
          navigate(-1);//go back to the previous page if the requested routes is not found in what the use can access
        }
      }
    }, 1000);
  }

  useMemo(() => {
    if (!(['/login', '/init_psd_recovery', '/complete_recover_password'].includes(location.pathname))) {
      utils.bootstrap(valuesStore, settingsStore,
        [settingsStore.acad_year, settingsStore.class_designation, settingsStore.course,
        settingsStore.grade, settingsStore.semester, settingsStore.settings, settingsStore.tables_metadata]
      );
    }
    // utils.bootstrapOthers(valuesStore, fetchItems);
    // hasRoute();

    return () => {
      //clean up here
    };
  }, [newLocation, valuesStore['loggedIn']]);

  return (
    <>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/login' element={<Login />} />
        <Route path='/init_psd_recovery' element={<InitRecoverPassword />} />
        <Route path='/complete_recover_password' element={<CompleterecoverPassword />} />
        <Route path='/student_password_recovery' element={<StudentPasswordRecovery />} />
        <Route path='/check_index_no' element={<CheckIndex />} />
        <Route path='/admin' element={<AdminHome />}>
          <Route path='uploads' element={<Uploads />} />
          <Route path='extra_grade' element={<ExtraGradesCorrection />} />
          <Route path='jobs' element={<Jobs />} />
          <Route path='acad_year' element={<AcadYear />} />
          <Route path='transcript' element={<Transcript />} />
          <Route path='broadsheet' element={<Broadsheet />} />
          <Route path='courses' element={<Courses />} />
          <Route path='course_management' element={<CourseManagement />} />
          <Route path='course_mgt_general' element={<CourseManagementGeneral />} />
          <Route path='course_mgt_extra' element={<CourseMgtExtra />} />

          <Route path='edu_level' element={<EduLevel />} />
          <Route path='grade' element={<Grade />} />

          <Route path='level' element={<Level />} />
          <Route path='mounted_courses' element={<MountedCourses />} />
          <Route path='mounted_courses_general' element={<MountedCoursesGeneral />} />

          <Route path='org_type' element={<OrgType />} />
          <Route path='batch_calendar' element={<BatchCalendar />} />
          <Route path='programs' element={<Programs />} />
          <Route path='scheme' element={<Scheme />} />
          <Route path='scoresheet_summary' element={<ScoreSheetSummary />} />
          <Route path='score_sheet' element={<ScoreSheet />} />
          <Route path='semesters' element={<Semester />} />
          <Route path='sessions' element={<Sessions />} />
          <Route path='settings' element={<AdminSetting />} />
          <Route path='students' element={<Student />} />
          <Route path='student_registration' element={<StudentRegistrations />} />
          <Route path='student_type' element={<StudentType />} />
          <Route path='admin' element={<Admin />} />
          <Route path='admin_files' element={<AdminFiles />} />
          <Route path='admin_roles' element={<AdminRoles />} />
          <Route path='admin_perms' element={<AdminPerms />} />
          <Route path='admin_role_files_link' element={<AdminRoleFilesLink />} />
          <Route path='admin_role_link' element={<AdminRoleLink />} />
          <Route path='admin_role_perm' element={<AdminRolePerm />} />
          <Route path='grade_change' element={<GradeChange />} />
          <Route path='grade_change_general' element={<GradeChangeGeneral />} />
          <Route path='grade_change_extra' element={<GradeChangeExtra />} />
          <Route path='mark_entry' element={<MarkEntry />} />
          <Route path='mark_entry_general' element={<MarkEntryGeneral />} />
          <Route path='grade_hx' element={<GradeHx />} />
          <Route path='admin_dept' element={<AdminDept />} />
          <Route path='registration_periods' element={<RegistrationPeriods />} />
          <Route path='change_password' element={<ChangePassword />} />
          <Route path='evaluation' element={<Evaluation />} />
          <Route path='course_evaluation' element={<CourseEvaluation />} />
          <Route index path='landing' element={<Landing />} />
          <Route path='*' element={<Page404 homepage='/admin/landing' />} />
        </Route>
        <Route path='*' element={<Page404 homepage='/admin/landing' />} />

        {/* <Route path='/' element={<Layout color='#8c033a' />}>
            <Route index element={<Home />} />
            <Route path="sell" element={valuesStore['loggedIn'] ? <Sell /> : <Navigate replace to={"/"} />} />
            <Route path=":category" element={<Category />} />
            <Route path=':category/:description' element={<Description />} />
            <Route path='chat' element={<Chat />} />
            <Route path='fprofile/:id' element={<UserFrontProfile />} />
            <Route path='search' element={<Search />} />
            <Route path='bprofile' element={<UserBackProfile />} />
            
            <Route path='all_categories' element={<AllCategories />} />
            <Route path='privacy_policy' element={<PrivacyPolicy />} />
            <Route path='terms_of_service' element={<TermsOfService />} />
          </Route> */}

      </Routes>
    </>

  )
}

//disable auto zoom in on iphone and ipad. iphone and ipad automatically zoom in when text size is less than 16px
if (utils.checkIsIOS()) {
  utils.addMaximumScaleToMetaViewport();
}

export default App;
